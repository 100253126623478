<template>
  <div class="text-center">
    <div v-if="showDownload">
      <Button :label="file.name" class="p-button-sm" type="button" icon="pi pi-download"
        @click="downloadFile(file.url, file.name)" />
    </div>
    <img v-if="$root.fileIsImage(file)" :src="blobUrl" class="width-pdf" alt="Ficheiro" />
    <div v-else-if="$root.fileIsMkvVideo(file)" class="text-center">
      <video class="post-videos" style="width: 90vw" controls :src="blobUrl" :autoplay="autoplay"></video>
    </div>
    <video v-else-if="$root.fileIsVideo(file)" style="max-height: 70vh; width: 90vw" controls :autoplay="autoplay">
      <source :src="blobUrl" :type="$root.getFileTypeVideoSource(file)" />
      <p>{{ $t("browser_does_not_support_html5_video") }}</p>
    </video>
    <iframe v-else-if="$root.fileIsPdf(file)" :src="blobUrl" :title="file.name" :name="file.name"
      :style="{ height: fullScreen ? '85vh' : '60vh', width: fullScreen ? '99vw' : pdfMaxWidth ? '100%' : '80vw' }"
      frameborder="0"></iframe>
    <Button v-else :label="file.name" type="button" icon="pi pi-file" class="p-button-sm"
      @click="downloadFile(file.url, file.name)" />
  </div>
</template>

<script>
export default {
  props: {
    file: { type: Object, default: null },
    showDownload: Boolean,
    fullScreen: Boolean,
    pdfMaxWidth: { type: Boolean, default: false },
    autoplay: { type: Boolean, default: false },
  },
  data() {
    return {
      blobUrl: "",
    }
  },
  computed: {
    pdfHeight() {
      return this.fullScreen ? "80vh" : "60vh";
    },
  },
  watch: {
    file() {
      if (this.$root.fileIsImage(this.file) || this.$root.fileIsVideo(this.file)
        || this.$root.fileIsMkvVideo(this.file) || this.$root.fileIsPdf(this.file)) {
        this.loadFile();
      }
    },
  },
  mounted() {
    if (this.file.url.startsWith("https://quadrante.")) {
      this.blobUrl = this.file.url;
    } else if (this.$root.fileIsImage(this.file) || this.$root.fileIsVideo(this.file)
      || this.$root.fileIsMkvVideo(this.file) || this.$root.fileIsPdf(this.file)) {
      this.loadFile();
    }
  },
  methods: {
    loadFile() {
      const regex = /^(\/)?(tmp|dir|imagens)\//;
      const url = this.file.url;
      if (regex.test(url)) {
        this.blobUrl = this.$root.getUrlFromFilePath(url);
      } else {
        this.blobUrl = null;
        const filename = url.split('/').pop().split('.')[0];
        this.$root
          .apiGetFile(this.$root.getApiFileName(filename))
          .then((response) => this.blobUrl = URL.createObjectURL(response.data));
      }
    },
  },
  downloadFile(url, filename) {
    this.isLoading = true;
    this.$root
      .apiGetFile(this.$root.getUrlFromFileToDownload(url))
      .then((response) => {
        this.isLoading = false;
        this.$root.downloadFile(
          response.data,
          response.headers,
          filename,
          url
        );
      })
      .catch(() => {
        this.isLoading = false;
        this.$root.toastError("file_not_found_or_stored");
      });
  },
};
</script>

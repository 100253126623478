/* eslint-disable no-undef */
import { createWebHistory, createRouter } from "vue-router";
import store from "@/store/index.js";

import login from "@/components/loginAndRecover/Login.vue";
import accountRecovery from "@/components/loginAndRecover/AccountRecovery.vue";
import resetPassword from "@/components/loginAndRecover/ResetPassword.vue";
import satisfactionSurvey from "@/components/loginAndRecover/SatisfactionSurvey.vue";
import rgpdPage from "@/components/loginAndRecover/RgpdPage.vue";
import askDocumentation from "@/components/loginAndRecover/AskDocumentation.vue";
import dashboard from "@/components/dashboard/Dashboard.vue";
import post from "@/components/posts/Post.vue";
import fileManager from "@/components/files/FileManager.vue";
import listProcesses from "@/components/processes/ListProcesses.vue";
import processPage from "@/components/processes/ProcessPage.vue";
import listServices from "@/components/services/ListServices.vue";
import listWebinars from "@/components/webinars/ListWebinars.vue";
import listBussolas from "@/components/bussola/ListBussolas.vue";

const routes = [
  { path: "/login", component: login, name: "login" },
  {
    path: "/account-recovery",
    component: accountRecovery,
    name: "account-recovery",
  },
  {
    path: "/reset-password",
    component: resetPassword,
    name: "reset-password",
  },
  {
    path: "/questionario-de-satisfacao/:token",
    component: satisfactionSurvey,
    name: "satisfaction-survey",
    props: true,
  },
  {
    path: "/pedido-documentacao/:token",
    component: askDocumentation,
    name: "ask-documentation",
    props: true,
  },
  {
    path: "/rgpd/:token",
    component: rgpdPage,
    name: "rgpd",
    props: true,
  },
  {
    path: "/",
    component: dashboard,
    name: "dashboard",
    meta: { requiresAuth: true },
    children: [
      { path: "publicacao/:id", component: post, name: "post", props: true },
      {
        path: "ficheiros",
        component: fileManager,
        name: "file-manager",
      },
      {
        path: "processos",
        component: listProcesses,
        name: "processes",
      },
      {
        path: "processo/:id",
        name: "process",
        component: processPage,
        props: true,
        meta: { parent: "process" },
      },
      {
        path: "servicos",
        component: listServices,
        name: "services",
      },
      {
        path: "webinars",
        component: listWebinars,
        name: "webinars",
      },
      {
        path: "bussolas",
        component: listBussolas,
        name: "bussolas",
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const rgpdToken = store.state.pendingClientRgpdToken;
  if (rgpdToken && requiresAuth) {
    store.commit("clearToken");
    next({
      name: "rgpd",
      params: { token: rgpdToken },
    });
    return;
  }
  if (!store.state.token && requiresAuth) {
    if (window.location.pathname == "/") {
      next({ name: "login" });
    } else {
      next({ name: "login", query: { next: window.location.pathname } });
    }
    return;
  }
  if (to.name === "login" && store.state.token) {
    next({ name: "dashboard" });
    return;
  }
  if (
    (to.name === "webinars" || to.name === "bussola") &&
    !store.state.hasBalcaoMais
  ) {
    next({ name: "dashboard" });
  }
  next();
});

export default router;

<template>
  <div>
    <div class="flex col-12">
      <ShowUserImage :url="$root.user.imgUrl" img-class="rounded mr-2 " width="30" height="30" />
      <Textarea v-model="message" auto-resize :placeholder="$t('new_post_comment_placeholder')" rows="1" cols="1"
        style="width: inherit;" />
    </div>
    <div class="grid">
      <!--upload-->
      <div class="col-6 pb-0">
        <FileUpload :id="'uploadFilesForComment' + id" :disabled="isLoading" :max-file-size="20000000"
          :url="$root.api.fileManagerSave" :choose-label="$t('attach')" :invalid-file-size-message="$t('max_file_size_allowed', { fileSize: '{1}' })
        " class="attach-files" name="files[]" mode="basic" auto multiple @before-send="beforeUpload"
          @upload="afterUpload" @error="isLoading = false" @progress="setUploadProgress" />
      </div>
      <!--submit-->
      <div class="col-6 flex-right-side pb-0">
        <Button :disabled="isLoading" :label="$t('comment_post')"
          :class="{ 'button-publish': $root.user && $root.user.hasBalcaoMais }" @click="validateInputs" />
      </div>
    </div>
    <ProgressBar v-if="isLoading && uploadProgress !== 100" :value="uploadProgress" style="padding-bottom: 25px" />
    <ProgressBar v-if="isLoading && (uploadProgress === 100 || uploadProgress == null)" class="mb-2"
      mode="indeterminate" />
    <FilesUploaded v-if="filesUploaded.length || filesPersisted.length" :files-uploaded="filesUploaded"
      :files-persisted="filesPersisted" class="mb-4 mt-2" @remove-file="removeFileByUrl" />
  </div>
</template>

<script>
import ProgressBar from "primevue/progressbar";
import FilesUploaded from "../files/FilesUploaded.vue";
import ShowUserImage from "../files/ShowUserImage.vue";

export default {
  components: { FilesUploaded, ProgressBar, ShowUserImage },
  props: { id: { type: Number, default: null } },
  emits: ["comment-created"],
  data() {
    return {
      message: "",
      isLoading: false,
      filesUploaded: [],
      filesPersisted: [],
      uploadProgress: 0,
    };
  },
  methods: {
    setUploadProgress(event) {
      this.uploadProgress = event.progress;
    },
    validateInputs() {
      if (!this.message || this.message.length == 0) {
        this.$root.toastError("message_is_required");
      } else {
        this.isLoading = true;
        if (this.filesUploaded.length > 0) {
          this.persistFile();
        } else {
          this.createComment();
        }
      }
    },
    beforeUpload(request) {
      this.isLoading = true;
      return request.xhr.setRequestHeader(
        "Authorization",
        this.$root.axiosGetAuth()
      );
    },
    afterUpload(request) {
      this.isLoading = false;
      let files = JSON.parse(request.xhr.response).data;
      files.forEach((file) => {
        file.context = `post/${this.id}`;
        this.filesUploaded.push(file);
      });
    },
    persistFile() {
      let files = {
        files: this.filesUploaded,
      };
      this.$root
        .apiPost(this.$root.api.fileManagerPersist, files)
        .then((response) => {
          this.filesUploaded = [];
          response.data.data.forEach((file) => {
            if (!file.url.startsWith("tmp/")) {
              this.filesPersisted.push(file);
            }
          });
          this.createComment();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    removeFileByUrl(url) {
      this.filesUploaded = this.filesUploaded.filter(
        (file) => file.url !== url
      );
      this.filesPersisted = this.filesPersisted.filter(
        (file) => file.url !== url
      );
    },
    createComment() {
      let parameters = {
        message: this.message,
        attachments: this.filesPersisted,
        postId: this.id,
      };
      this.$root
        .apiPost(this.$root.api.postComments, parameters)
        .then((response) => {
          this.isLoading = false;
          this.$root.toastSuccess(response.data.msg_code);
          this.$emit("comment-created");
          this.resetData();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    resetData() {
      this.isLoading = false;
      this.message = "";
      this.filesUploaded = this.filesPersisted = [];
    },
  },
};
</script>

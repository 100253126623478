<template>
  <footer :class="showKeepWindowOpen ? 'pt-6' : ''">
    <div class="grid sidebar-footer">
      <div v-if="showKeepWindowOpen" class="col-12 w-auto">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon bc-transparent">
            <Checkbox v-model="keepWindowOpen" binary />
          </span>
          <span class="p-inputgroup-addon">
            <label>{{ $t("keep_window_open") }}</label>
          </span>
        </div>
      </div>
      <div class="col-12 w-auto">
        <Button
          :disabled="isLoading || !canSumbit"
          :loading="isLoading"
          :label="submitText"
          icon="pi pi-check"
          @click="submit"
        />
      </div>
      <div class="col-12 w-auto">
        <Button
          :disabled="isLoading"
          :loading="isLoading"
          class="p-button-outlined"
          icon="pi pi-times"
          label="Cancelar"
          @click="cancel"
        />
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    isLoading: Boolean,
    submitText: { type: String, default: null },
    showKeepWindowOpen: { type: Boolean, default: false },
    canSumbit: { type: Boolean, default: true },
  },
  emits: ["submit", "cancel"],
  data() {
    return { keepWindowOpen: false };
  },
  methods: {
    submit() {
      this.$emit("submit", this.keepWindowOpen);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

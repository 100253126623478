<template>
  <div>
    <ul class="layout-menu">
      <li>
        <router-link :to="{ name: 'dashboard' }" class="p-ripple" @click="routerChanged">
          <i class="layout-menuitem-icon pi pi-fw pi-home" />
          <span class="layout-menuitem-text">{{ $t("dashboard") }}</span>
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'file-manager' }" class="p-ripple" @click="routerChanged">
          <i class="layout-menuitem-icon pi pi-fw pi-file" />
          <span class="layout-menuitem-text">{{ $t("my_files") }}</span>
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'processes' }" :class="{
          'router-link-exact-active': $route.meta.parent === 'process',
        }" @click="routerChanged">
          <i class="layout-menuitem-icon pi pi-fw pi-cog" />
          <span class="layout-menuitem-text">{{ $t("my_processes") }}</span>
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'services' }"
          :class="{ 'router-link-exact-active': $route.meta.parent === 'service' }" @click="routerChanged">
          <i class="layout-menuitem-icon pi pi-fw pi-check-square" />
          <span class="layout-menuitem-text">{{ $t("my_services") }}</span>
        </router-link>
      </li>
      <template v-if="$root.user.hasBalcaoMais">
        <li>
          <router-link :to="{ name: 'webinars' }"
            :class="{ 'router-link-exact-active': $route.meta.parent === 'webinars' }" @click="routerChanged">
            <i class="layout-menuitem-icon pi pi-fw pi-video" />
            <span class="layout-menuitem-text">{{ $t("webinars") }}</span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'bussolas' }" class="p-ripple" @click="routerChanged">
            <i class="layout-menuitem-icon pi pi-fw pi-book" />
            <span class="layout-menuitem-text">Revista a Bússola</span>
          </router-link>
        </li>
      </template>
    </ul>
    <ul v-if="cardImageUrl" class="-ml-4 mr-3">
      <ShowUserImage :url="cardImageUrl" img-class="w-full" style="border-radius: 8px;" />
    </ul>
    <ul class="contacts">
      <Contacts v-if="contacts" :contacts="contacts" />
    </ul>
  </div>
</template>

<script>
import Contacts from "./Contacts.vue";
import ShowUserImage from "../files/ShowUserImage.vue";

export default {
  components: { Contacts, ShowUserImage },
  props: { contacts: { type: Array, default: null } },
  emits: ["router-changed"],
  computed: {
    cardImageUrl() {
      return this.$root.user.cardImageUrl;
    },
  },
  methods: {
    routerChanged() {
      this.$emit("router-changed");
    },
  },
};
</script>

<template>
  <ProgressBar v-if="isLoading" mode="indeterminate" class="mb-4" />
  <TreeTable v-else :value="files" :expanded-keys="expandedKeys" :filters="filters" class="p-treetable-sm mb-5"
    sort-mode="single" filter-mode="lenient" responsive-layout="scroll" removable-sort>
    <template #header>
      <div class="p-fluid grid">
        <div class="col-12 sm:col-4">
          <span>{{ $t("name") }}:</span>
          <InputText v-model="filters['name']" type="text" placeholder="Nome" />
        </div>
        <div class="col-12 sm:col-4">
          <span>{{ $t("date") }}:</span>
          <InputText v-model="filters['createdAt']" type="text" placeholder="Data" />
        </div>
      </div>
    </template>
    <Column field="name" :header="$t('name')" expander sortable />
    <Column field="createdAt" :header="$t('date')" header-class="sm-invisible" body-class="sm-invisible" sortable />
    <Column header-class="text-center" body-class="text-center">
      <template #body="slotProps">
        <PostFile v-if="slotProps.node.data.type !== 'folder'" :file="slotProps.node.data" :display-name="false"
          class="file-manager-displayed-file d-contents" @show-file="setShowFile(slotProps.node.data)" />
        <Button v-if="slotProps.node.data.type !== 'folder'" :icon="
          slotProps.node.data.type && slotProps.node.data.type === 'folder'
            ? 'pi pi-plus'
            : 'pi pi-pencil'
        " class="p-button-warning ml-2" type="button" @click="toggle($event, slotProps.node)" />
      </template>
    </Column>
    <template #footer>
      <div class="text-left">
        <Button class="mr-4" icon="pi pi-refresh" @click="getFiles" />
        <Button :label="$t('open_folders')" class="mr-4" type="button" icon="pi pi-plus" @click="expandAll" />
        <Button :label="$t('close_folders')" type="button" icon="pi pi-minus" @click="collapseAll" />
      </div>
    </template>
  </TreeTable>
  <TieredMenu ref="opMenu" :model="items" popup />
  <DialogFiles :files="selectedNode ? [selectedNode.data] : []" :display-files="showFile" @closed="showFile = false" />
</template>

<script>
import TieredMenu from "primevue/tieredmenu";
import TreeTable from "primevue/treetable";
import PostFile from "../posts/PostFile.vue";
import DialogFiles from "./DialogFiles.vue";

export default {
  components: { TreeTable, PostFile, TieredMenu, DialogFiles },
  data() {
    return {
      files: [],
      isLoading: false,
      expandedKeys: {},
      filters: {},
      selectedNode: {},
      showAddFolder: false,
      showAddFiles: false,
      showFile: false,
      folderPath: "",
      items: [
        {
          label: this.$t("view_file"),
          icon: "pi pi-fw pi-search",
          command: () => {
            this.showFile = true;
          },
          visible: () => this.selectedNode.data.type !== "folder",
        },
        {
          label: this.$t("download"),
          icon: "pi pi-fw pi-external-link",
          command: () => {
            this.downloadFile();
          },
          visible: () => this.selectedNode.data.type !== "folder",
        },
      ],
    };
  },
  created() {
    this.getFiles();
  },
  methods: {
    getFiles() {
      this.resetVariables();
      this.isLoading = true;
      this.$root
        .apiGet(this.$root.api.processFileUrlsClient)
        .then((response) => {
          this.$root
            .apiGet(this.$root.getApiClientFiles(this.$root.user.id), {
              params: { processFileUrls: response.data.processFileUrls, },
            })
            .then((response) => {
              this.isLoading = false;
              this.files = response.data.data;
            })
            .catch((error) => {
              this.isLoading = false;
              this.$root.toastError(error.response.data.msg_code);
            });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    expandNode(node) {
      if (node.children && node.children.length) {
        this.expandedKeys[node.key] = true;
        for (let child of node.children) {
          this.expandNode(child);
        }
      }
    },
    expandAll() {
      for (let node of this.files) {
        this.expandNode(node);
      }
      this.expandedKeys = { ...this.expandedKeys };
    },
    collapseAll() {
      this.expandedKeys = {};
    },
    toggle(event, node) {
      this.$refs.opMenu.toggle(event);
      this.selectedNode = node;
      if (this.selectedNode && this.selectedNode.data.path) {
        this.folderPath = this.selectedNode.data.path;
      }
    },
    downloadFile() {
      let url = this.selectedNode.data.url;
      let filename = this.selectedNode.data.name;
      this.$root
        .apiGetFile(this.$root.getUrlFromFileToDownload(url))
        .then((response) => {
          this.$root.downloadFile(
            response.data,
            response.headers,
            filename,
            url
          );
        })
        .catch((error) => {
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    resetVariables() {
      this.selectedNode = {};
      this.showAddFolder = false;
      this.showAddFiles = false;
    },
    setShowFile(node) {
      this.selectedNode.data = node;
      this.showFile = true;
    },
  },
};
</script>

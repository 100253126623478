<template>
    <div>
        <div class="layout-wrapper layout-static layout-menu-light layout-topbar-blue">
            <div class="layout-topbar">
                <img :src="$root.images.beLogoTransparentBackground" alt="logo" class="layout-topbar-logo" />
            </div>
        </div>
        <main>
            <div class="flex justify-content-center">
                <InlineMessage v-if="!isTokenValid && messageError" severity="error" class="mt-3">
                    {{ $t(messageError) }}
                </InlineMessage>
                <Skeleton v-else-if="!isTokenValid" width="360px" height="460px"></Skeleton>
                <div v-else class="card shadow-3 w-9">
                    <h2 class="mb-6">{{ askDocumentation.title }}</h2>
                    <form v-show="!messageSuccess" class="p-fluid formgrid grid">
                        <div v-for="(documentationType, key) in formObject.documentationTypes" :key="key"
                            class="field col-12">
                            <h2>{{ documentationType.name }}</h2>
                            <FileUpload :disabled="documentationType.isLoading" :max-file-size="20000000"
                                :url="$root.api.fileManagerAskDocumentationsSave" :invalid-file-size-message="$t('max_file_size_allowed', { fileSize: '{1}' })
                                    " name="files[]" :choose-label="$t('attach')" mode="basic" auto multiple
                                choose-icon="pi pi-paperclip" upload-icon="pi pi-paperclip"
                                @before-send="beforeUploadDt(key, $event)" @upload="afterUploadDt(key, $event)"
                                @error="isLoading = false" @progress="setUploadProgressDt(key, $event)" />
                            <FilesUploaded v-if="documentationType.fileUploaded"
                                :files-uploaded="[documentationType.fileUploaded]" class="mb-4 mt-2"
                                :disabled="documentationType.isLoading" @remove-file="removeFileByUrlDt(key, $event)" />
                            <div class="col-12 md:col-6 lg:col-4 mt-4">
                                <label>Data validade (caso aplicável)</label>
                                <Calendar v-model="documentationType.expirationDate" placeholder="31/12/2020"
                                    date-format="dd/mm/yy" show-icon />
                            </div>
                            <div v-if="documentationType.inputs" class="col-12 p-fluid formgrid grid mt-3">
                                <div v-for="(input, inputKey) in documentationType.inputs" :key="inputKey"
                                    class="field col-12 md:col-6 lg:col-4">
                                    <div v-if="input.id">
                                        <div class="line-height-2">
                                            <label :for="`input${input.id}`">
                                                {{ input.name }}
                                                <span v-if="input.isRequired && input.type !== 'checkbox'">*</span>
                                            </label>
                                            <small v-if="input.description" class="ml-1">{{ input.description }}</small>
                                        </div>
                                        <div v-if="input.type === 'text'">
                                            <InputText v-model="input.value" type="text" />
                                        </div>
                                        <div v-else-if="input.type === 'number'">
                                            <InputNumber v-model="input.value" :max-fraction-digits="2" placeholder="0" />
                                        </div>
                                        <div v-else-if="input.type === 'list'">
                                            <Dropdown v-model="input.value" :options="input.arrayValues"
                                                placeholder="Selecione" filter />
                                        </div>
                                        <div v-else-if="input.type === 'button-list'">
                                            <SelectButton v-model="input.value" :options="input.arrayValues" />
                                        </div>
                                        <div v-else-if="input.type === 'date'">
                                            <Calendar v-model="input.value" placeholder="31/12/1950" date-format="dd/mm/yy"
                                                show-icon />
                                        </div>
                                        <div v-else-if="input.type === 'checkbox'">
                                            <Checkbox :id="`input${input.id}`" v-model="input.value" class="mr-1" binary />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <template v-if="formObject.inputTypes">
                            <div v-for="(input, key) in formObject.inputTypes" :key="key" class="field col-12 mt-3">
                                <div v-if="input.id">
                                    <div class="line-height-2">
                                        <h3 :for="`processInput${input.id}`">{{ input.name }}</h3>
                                        <small v-if="input.description" class="ml-1">{{ input.description }}</small>
                                    </div>
                                    <div v-if="input.type === 'upload'">
                                        <FileUpload v-if="!input.value" :disabled="input.isLoading"
                                            :max-file-size="20000000" :url="$root.api.fileManagerAskDocumentationsSave"
                                            :invalid-file-size-message="$t('max_file_size_allowed', { fileSize: '{1}' })
                                                " name="files[]" :choose-label="$t('attach')" mode="basic" auto
                                            multiple choose-icon="pi pi-paperclip" upload-icon="pi pi-paperclip"
                                            @before-send="beforeUploadIt(key, $event)" @upload="afterUploadIt(key, $event)"
                                            @error="isLoading = false" @progress="setUploadProgressIt(key, $event)" />
                                        <FilesUploaded v-if="(input.filesUploaded && input.filesUploaded.length)"
                                            :files-uploaded="input.filesUploaded" class="mb-4 mt-2"
                                            :disabled="input.isLoading" @remove-file="removeFileByUrlIt(key, $event)" />
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div class="resolution-button-bar mt-4 col-12">
                            <Button label="Enviar documentação" icon="pi pi-envelope" class="p-button-outlined w-min"
                                autofocus :disabled="isLoading" @click="submit" />
                        </div>
                        <InlineMessage v-if="messageError" severity="error" class="mt-3">
                            {{ $t(messageError) }}
                        </InlineMessage>
                        <ProgressBar v-if="isLoading" mode="indeterminate" class="mt-2" />
                    </form>
                    <InlineMessage v-if="messageSuccess" severity="success" class="mt-3">
                        {{ $t(messageSuccess) }}
                    </InlineMessage>
                </div>
            </div>
        </main>
    </div>
</template>

<script >
import InlineMessage from "primevue/inlinemessage";
import Skeleton from "primevue/skeleton";
import SelectButton from "primevue/selectbutton";
import InputNumber from "primevue/inputnumber";
import { ref } from "vue";
import FilesUploaded from "../files/FilesUploaded.vue";

export default {
    components: { InlineMessage, Skeleton, FilesUploaded, SelectButton, InputNumber },
    props: { token: { type: String, default: null } },
    setup() {
        const formObject = ref({});
        const askDocumentation = ref(null);
        const isLoading = ref(false);
        const isTokenValid = ref(false);
        const messageError = ref("");
        const messageSuccess = ref("");
        return {
            formObject, isLoading, isTokenValid, messageError, messageSuccess, askDocumentation,
        };
    },
    created() {
        this.tokenConfirmation();
    },
    mounted() {
        if (!this.token) {
            this.$router.push({ name: "login" }).catch(() => { });
        }
    },
    methods: {
        tokenConfirmation() {
            this.messageError = this.messageSuccess = null;
            this.$root
                .apiGet(this.$root.getApiAskDocumentation(this.token))
                .then((response) => {
                    this.isTokenValid = true;
                    this.askDocumentation = response.data.data;
                    this.formObject.documentationTypes = [...response.data.data.documentationTypes];
                    this.formObject.inputTypes = [...response.data.data.inputTypes];
                })
                .catch((error) => {
                    this.isTokenValid = false;
                    this.messageError = error.response.data.msg_code;
                });
        },
        submit() {
            this.messageError = this.messageSuccess = null;
            this.isLoading = true;
            this.$root.axiosSetAuth(null, false);
            this.persistFiles();
        },
        persistFiles() {
            const filesUploaded = [];
            this.formObject.inputTypes.forEach((input) => {
                if (input.filesUploaded) {
                    input.filesUploaded.forEach((fileUploaded) => {
                        fileUploaded.context = `process/${this.askDocumentation.processId}/inputs`;
                        filesUploaded.push(fileUploaded);
                    });
                }
            });
            this.formObject.documentationTypes.forEach(documentationType => {
                if (documentationType.fileUploaded) {
                    documentationType.fileUploaded.context = `client/${this.askDocumentation.clientId}`;
                    filesUploaded.push(documentationType.fileUploaded);
                }
            });
            const parameters = { files: filesUploaded, clientId: this.askDocumentation.clientId };
            this.$root
                .apiPost(this.$root.api.fileManagerAskDocumentationsPersist, parameters)
                .then((response) => {
                    let fileUrlName = null;
                    let filePersisted = null;
                    this.formObject.inputTypes.forEach((input) => {
                        if (input.filesUploaded && input.filesUploaded.length) {
                            input.filesUploaded.forEach((fileUploaded) => {
                                fileUrlName = fileUploaded.url.substring(
                                    fileUploaded.url.lastIndexOf("/") + 1
                                );
                                filePersisted = response.data.data.find(
                                    (filePersisted) => filePersisted.url.endsWith(fileUrlName)
                                );
                                if (!input.filesPersisted) {
                                    input.filesPersisted = [];
                                }
                                if (filePersisted) {
                                    delete input.filesUploaded;
                                    input.filesPersisted.push(filePersisted);
                                }
                            });
                        }
                    });
                    this.formObject.documentationTypes.forEach((documentationType) => {
                        if (documentationType.fileUploaded && documentationType.fileUploaded) {
                            fileUrlName = documentationType.fileUploaded.url.substring(documentationType.fileUploaded.url.lastIndexOf("/") + 1);
                            filePersisted = response.data.data.find((filePersisted) => filePersisted.url.endsWith(fileUrlName));
                            if (filePersisted) {
                                delete documentationType.fileUploaded;
                                documentationType.filePersisted = filePersisted;
                            }
                        }
                    });
                    this.updateInputTypes();
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$root.toastError(error.response.data.msg_code);
                });
        },
        updateInputTypes() {
            const parameters = {
                inputTypes: this.formObject.inputTypes,
                documentationTypes: this.formObject.documentationTypes,
            };
            this.$root
                .apiPut(this.$root.getApiAskDocumentationInputValues(this.token), parameters)
                .then(() => {
                    this.isLoading = false;
                    this.messageSuccess = "A documentação foi enviada";
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$root.toastError(error.response.data.msg_code);
                });
        },
        setUploadProgressIt(key, event) {
            this.formObject.inputTypes[key].uploadProgress = event.progress;
        },
        beforeUploadIt(key, request) {
            this.formObject.inputTypes[key].isLoading = true;
            return request.xhr.setRequestHeader(
                "Authorization",
                this.$root.axiosGetAuth()
            );
        },
        afterUploadIt(key, request) {
            this.formObject.inputTypes[key].isLoading = false;
            let files = JSON.parse(request.xhr.response).data;
            files.forEach((file) => {
                file.context = "pending_posts";
                if (!this.formObject.inputTypes[key].filesUploaded) {
                    this.formObject.inputTypes[key].filesUploaded = [];
                }
                this.formObject.inputTypes[key].filesUploaded.push(file);
            });
        },
        removeFileByUrlIt(key, url) {
            if (this.formObject.inputTypes[key].filesUploaded && this.formObject.inputTypes[key].filesUploaded.length) {
                this.formObject.inputTypes[key].filesUploaded =
                    this.formObject.inputTypes[key].filesUploaded.filter((file) => file.url !== url);
            }
            if (this.formObject.inputTypes[key].filesPersisted && this.formObject.inputTypes[key].filesPersisted.length) {
                this.formObject.inputTypes[key].filesPersisted =
                    this.formObject.inputTypes[key].filesPersisted.filter((file) => file.url !== url);
            }
        },
        setUploadProgressDt(key, event) {
            this.formObject.documentationTypes[key].uploadProgress = event.progress;
        },
        beforeUploadDt(key, request) {
            this.formObject.documentationTypes[key].isLoading = true;
            return request.xhr.setRequestHeader(
                "Authorization",
                this.$root.axiosGetAuth()
            );
        },
        afterUploadDt(key, request) {
            this.formObject.documentationTypes[key].isLoading = false;
            this.formObject.documentationTypes[key].fileUploaded = JSON.parse(request.xhr.response).data[0];
            this.formObject.documentationTypes[key].fileUploaded.context = `client/${this.askDocumentation.clientId}`;
        },
        removeFileByUrlDt(key) {
            this.formObject.documentationTypes[key].fileUploaded = this.formObject.documentationTypes[key].filePersisted = null;
        },
    },
};

</script>
<template>
    <h1 v-if="process.state && process.state !== 'ongoing'" class="mb-4 text-primary">{{
    translateProcessStateToPt(process.state)
    }}</h1>

    <!-- Phase -->
    <div v-if="process.phases && process.phases.length" class="mb-4">
        <h3>{{ $t('process_phase') }}</h3>
        <div v-for="(phase, index) in process.phases" :key="phase.id"
            :class="{ 'font-bold': phase.id == process.phase.id }">{{ index + 1 }} - {{ phase.name }}</div>
    </div>

    <!-- Files -->
    <div v-if="process.files && process.files.length" class="mb-4">
        <h3>{{ $t('files') }}</h3>

        <div v-for="(files, description) in process.filesOptimized" :key="description" class="mb-2">
            <div class="text-sm mb-2 mt-4">{{ description }}</div>
            <div v-for="file in files" :key="file.key">
                {{ file.userName }}
                <Button class="p-button-text text-left w-12" @click="setDisplayFiles(file.key, null)">
                    <img loading="lazy" :src="`${$root.images.filetypes}/${file.url.split('.').pop()}.png`" width="30"
                        height="30" alt="Tipo de ficheiro" @error="$root.setDefaultFiletype" />
                    {{ file.name }}
                </Button>
            </div>
        </div>

        <div v-for="(files, name) in process.opportunityFiles" :key="name" class="mb-2">
            <div class="text-sm mb-2 mt-4">{{ name }}</div>
            <div v-for="(file, key) in files" :key="key">
                {{ file.userName }}
                <Button class="p-button-text text-left w-12" aria-haspopup="true"
                    @click="setDisplayFiles(null, file.url)" @contextmenu="showContextMenu($event, file)">
                    <img loading="lazy" :src="`${$root.images.filetypes}/${file.url.split('.').pop()}.png`" width="30"
                        height="30" alt="Tipo de ficheiro" @error="$root.setDefaultFiletype" />
                    {{ file.name }}
                </Button>
            </div>
        </div>
    </div>
    <DialogFiles :files="process.files" :displayFiles="fileSelectedIndex != null" :fileSelectedIndex="fileSelectedIndex"
        @closed="setDisplayFiles(null, null)" />
</template>

<script>
import useDropdownOptions from "../../composables/useDropdownOptions.js";
import DialogFiles from "../files/DialogFiles.vue";

export default {
    name: "ProcessInfo",
    components: { DialogFiles },
    props: { process: { type: Object, default: null } },
    setup() {
        const { translateProcessStateToPt } = useDropdownOptions();
        return { translateProcessStateToPt };
    },
    data() {
        return { fileSelectedIndex: null };
    },
    methods: {
        setDisplayFiles(index, url) {
            if (index == null) {
                index = this.process.files.findIndex((file) => file && file.url === url);
                if (index == -1) {
                    index = null;
                }
            }
            this.fileSelectedIndex = index;
        },
    },
};
</script>

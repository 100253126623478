<template>
    <div>
        <div class="layout-wrapper layout-static layout-menu-light layout-topbar-blue">
            <div class="layout-topbar">
                <img :src="$root.images.beLogoTransparentBackground" alt="logo" class="layout-topbar-logo" />
            </div>
        </div>
        <main>
            <div class="flex justify-content-center mx-6">
                <InlineMessage v-if="showSuccess" severity="success" class="mt-3">
                    O tratamento de dados pessoais foi autorizado
                </InlineMessage>
                <InlineMessage v-else-if="!isTokenValid && messageError" severity="error" class="mt-3">
                    {{ $t(messageError) }}
                </InlineMessage>
                <Skeleton v-else-if="!isTokenValid" width="360px" height="460px"></Skeleton>
                <div v-else class="card shadow-3">
                    <div v-html="$sanitize(page)"></div>
                    <div class="mb-4">
                        <Button label="Autorizo o tratamento dos meus dados pessoais"
                            class="p-button-rounded p-button-outlined" @click="confirmRgpd" />
                        <Button label="Recusar" class="ml-4 p-button-rounded p-button-outlined" @click="cancel" />
                    </div>
                    <div v-if="showWarning" class="text-red">Caso não concorde com os Termos do RGPD não poderemos
                        dar seguimento ao seu pedido</div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import InlineMessage from "primevue/inlinemessage";
import Skeleton from "primevue/skeleton";
import { ref } from "vue";

export default {
    components: { InlineMessage, Skeleton },
    props: { token: { type: String, default: null } },
    setup() {
        const isLoading = ref(false);
        const isTokenValid = ref(false);
        const messageError = ref(null);
        const showSuccess = ref(false);
        const showWarning = ref(false);
        const page = ref(null);

        return { isLoading, isTokenValid, messageError, showSuccess, showWarning, page };
    },
    created() {
        this.tokenConfirmation();
    },
    mounted() {
        if (!this.token) {
            this.$router.push({ name: "login" }).catch(() => { });
        }
    },
    methods: {
        tokenConfirmation() {
            this.messageError = null;
            this.$root
                .apiGet(this.$root.getApiClientRgpds(this.token))
                .then((response) => {
                    this.isTokenValid = true;
                    if (!response.data.isEmailPending) {
                        this.setShowSuccess();
                    }
                    this.page = response.data.data;
                })
                .catch((error) => {
                    this.isTokenValid = false;
                    this.messageError = error.response.data.msg_code;
                });
        },
        confirmRgpd() {
            this.messageError = null;
            this.isLoading = true;
            this.$root.axiosSetAuth(null, false);
            this.$root
                .apiPostUnauthenticated(this.$root.getApiClientRgpds(this.token))
                .then(() => {
                    this.isLoading = false;
                    this.setShowSuccess();
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.messageError = error.response.data.msg_code;
                });
        },
        setShowSuccess() {
            this.showSuccess = true;
            setTimeout(() => { this.goToLogin() }, 10000);
        },
        cancel() {
            this.showWarning ? this.goToLogin() : this.showWarning = true;
        },
        goToLogin() {
            this.$router.push({ name: "login" });
        },
    }
};
</script>

<style scoped>
.layout-topbar {
    display: list-item;
    top: 0;
}

main {
    margin-top: 120px;
    text-align: center;
}
</style>
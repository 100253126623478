<template>
    <div>
        <div class="p-fluid grid mb-4">
            <div class="col-12 sm:col-6 lg:col-4">
                <span>Título:</span>
                <InputText v-model="title" type="text" placeholder="Título" />
            </div>
            <div class="col-12 sm:col-6 lg:col-4">
                <span>Ano:</span>
                <InputText v-model="year" type="text" placeholder="Ano" />
            </div>
        </div>
        <div class="grid">
            <div v-for="bussola in filteredBussolas" :key="bussola.id" class="col-12 sm:col-6 lg:col-4">
                <ShowBussola :bussola="bussola" />
            </div>
        </div>
    </div>
</template>

<script>
import ShowBussola from "./ShowBussola.vue";

export default {
    components: { ShowBussola },
    data() {
        return {
            bussolas: [],
            title: null,
            year: null,
        };
    },
    computed: {
        filteredBussolas() {
            this.year; this.title;
            return this.bussolas.filter(bussola => {
                const title = bussola.title.rendered;
                const yearMatch = title.match(/(\d+)$/);
                return (!this.title || title.toLowerCase().includes(this.title.toLowerCase()))
                    && (!this.year || !yearMatch || yearMatch[0] == this.year);
            });
        },
    },
    created() {
        this.getBussolas();
    },
    methods: {
        getBussolas() {
            let params = { dataType: "client", isActive: true };
            this.$root
                .apiGet(this.$root.api.externalApiBussolaPosts, { params })
                .then((response) => {
                    this.bussolas = response.data;
                })
                .catch((error) => {
                    this.$root.toastError(error.response.data.msg_code);
                });
        },
    },
};
</script>
<template>
    <div v-if="bussola" class="p-3 pb-0  b-round flex bg-white">
        <img v-if="bussola.id" :src="thumbnail" class="pr-2 pb-3" style="height: 200px;" />
        <div class="w-full flex flex-column justify-content-between">
            <div class="align-items-center pb-3">
                <div class="text-700 text-sm">Edição nº{{ number }}</div>
                <div class="text-700 text-sm capitalize">{{ publishDate }}</div>
                <h3 class="mt-1" v-html="bussola.title.rendered"></h3>
                <div class="text-700 text-xs"></div>
            </div>
            <div class="text-xs text-right ml-auto w-max mr4 py-1 px-4 text-0 bg-purple border-bottom-none border-3 b-round  cursor-pointer"
                @click="openInNewTab">
                Ver mais »</div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    props: { bussola: { type: Object, default: null } },
    setup() {
        const thumbnail = ref("");
        return { thumbnail };
    },
    computed: {
        number() {
            if (this.bussola && this.bussola.title && this.bussola.title.rendered) {
                const numberMatch = this.bussola.title.rendered.match(/#(\d+)/)
                return numberMatch ? numberMatch[1] : null;
            }
            return null;
        },
        publishDate() {
            if (this.bussola && this.bussola.title && this.bussola.title.rendered) {
                const dateMatch = this.bussola.title.rendered.match(/(\w+ \d+)$/)
                return dateMatch ? dateMatch[1] : null;
            }
            return null;
        },
    },
    mounted() {
        if (this.bussola && this.bussola.x_metadata && this.bussola.x_metadata._elementor_data) {
            const jsonObject = JSON.parse(this.bussola.x_metadata._elementor_data);
            if (!jsonObject) {
                return null;
            }
            const index = jsonObject.length > 1 ? 1 : 0;
            if (jsonObject && jsonObject[index] && jsonObject[index].elements && jsonObject[index].elements[0] && jsonObject[index].elements[0].elements
                && jsonObject[index].elements[0].elements[0] && jsonObject[index].elements[0].elements[0].settings) {
                let shortcode = jsonObject[index].elements[0].elements[0].settings.shortcode;
                if (!shortcode) {
                    shortcode = jsonObject[index].elements[0].elements[0].settings.editor;
                }
                if (shortcode) {
                    const id = shortcode.match(/id=\\?"(\d+)\\?"/)[1];
                    this.thumbnail = this.$root.getApiExternalBussolaThumbnail(id);
                }
            }
        }
    },
    methods: {
        openInNewTab() {
            window.open(this.bussola.link, "_blank");
        },
    },
};
</script>
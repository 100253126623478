const imagesPath = {
  data() {
    return {
      images: {
        defaultPicture: "/assets/images/user-picture-default.svg",
        beLogoTransparentBackground:
          "/assets/images/be-logo-transparent-background.png",
        beLogoBall: "/assets/images/be-logo-ball.png",
        pafSymbol: "/assets/images/paf-symbol.png",
        balcaoMaisIcon: "/assets/images/balcao-mais-icon.webp",
        balcaoMaisLogo: "/assets/images/balcao-mais-logo-branco.webp",
        beLogo: "/assets/images/be-logo.png",
        birthDate: "/assets/images/aniversario.png",
        filetypes: "/assets/images/filetypes",
        campaignReturnToPortugal: "/assets/images/campaign/return-to-portugal.jpg",
        financialCourse: "/assets/images/campaign/financial-course.webp",
      },
    };
  },
};

export default imagesPath;

<template>
    <div>
        <div class="layout-wrapper layout-static layout-menu-light layout-topbar-blue">
            <div class="layout-topbar">
                <img :src="$root.images.beLogoTransparentBackground" alt="logo" class="layout-topbar-logo" />
            </div>
        </div>
        <main>
            <div class="flex justify-content-center">
                <InlineMessage v-if="!isTokenValid && messageError" severity="error" class="mt-3">
                    {{ $t(messageError) }}
                </InlineMessage>
                <Skeleton v-else-if="!isTokenValid" width="360px" height="460px"></Skeleton>
                <div v-else class="card shadow-3">
                    <h2 class="mb-0">Inquérito de satisfação</h2>
                    <form v-show="!messageSuccess" class="p-fluid formgrid">
                        <div v-if="isForfeit" class="field col-12 mt-4">
                            <h5>Qual a razão da sua desistência?</h5>
                            <div class="field-checkbox">
                                <Checkbox id="price" v-model="formObject.forfeitReasons" name="forfeitReason"
                                    value="O valor do serviço" />
                                <label for="price" class="text-left">O valor do serviço</label>
                            </div>
                            <div class="field-checkbox">
                                <Checkbox id="incompatibleTime" v-model="formObject.forfeitReasons" name="forfeitReason"
                                    value="O atendimento não foi de encontro às minhas expetativas" />
                                <label for="incompatibleTime" class="text-left">O atendimento não foi de encontro às
                                    minhas expetativas</label>
                            </div>
                            <div class="field-checkbox">
                                <Checkbox id="anotherProvider" v-model="formObject.forfeitReasons" name="forfeitReason"
                                    value="Encontrei uma alternativa aos vossos serviços" />
                                <label for="anotherProvider" class="text-left">Encontrei uma alternativa aos vossos
                                    serviços</label>
                            </div>
                            <div class="field-checkbox">
                                <Checkbox id="wrongTime" v-model="formObject.forfeitReasons" name="forfeitReason"
                                    value="Não é a altura certa" />
                                <label for="wrongTime" class="text-left">Não é a altura certa</label>
                            </div>
                        </div>
                        <div v-if="!isForfeit" class="field col-12 mt-4">
                            <h5>Qualidade do atendimento (1 a 10)<span v-show="formObject.qualityOfService > 0">:
                                    {{ formObject.qualityOfService }}</span></h5>
                            <Rating v-model="formObject.qualityOfService" :stars="10" :cancel="false" />
                        </div>
                        <div v-if="!isForfeit" class="field col-12 mt-4">
                            <h5>O que achou do tempo de resposta (1 a 10)<span v-show="formObject.timeResponse > 0">:
                                    {{ formObject.timeResponse }}</span></h5>
                            <Rating v-model="formObject.timeResponse" :stars="10" :cancel="false" />
                        </div>
                        <div v-if="!isForfeit" class="field col-12 mt-4">
                            <h5>Qual a probabilidade de recomendar o Balcão? (1 a 10)<span
                                    v-show="formObject.willingnessToRecommend > 0">
                                    : {{ formObject.willingnessToRecommend }}</span></h5>
                            <Rating v-model="formObject.willingnessToRecommend" :stars="10" :cancel="false" />
                        </div>
                        <div v-if="!isForfeit" class="field col-12 mt-4">
                            <h5>Qual a avaliação geral que faz da empresa? (1 a 10)<span
                                    v-show="formObject.generalEvaluation > 0">
                                    : {{ formObject.generalEvaluation }}</span></h5>
                            <Rating v-model="formObject.generalEvaluation" :stars="10" :cancel="false" />
                        </div>
                        <div class="field col-12 mt-4">
                            <h5>Obervações:</h5>
                            <Textarea v-model="formObject.description" auto-resize rows="5" cols="50" />
                        </div>

                        <div class="resolution-button-bar mt-4">
                            <Button label="Submeter" icon="pi pi-envelope" class="p-button-outlined w-min" autofocus
                                :disabled="isDisabled || isLoading" @click="submit" />
                        </div>
                        <InlineMessage v-if="messageError" severity="error" class="mt-3">
                            {{ $t(messageError) }}
                        </InlineMessage>
                        <ProgressBar v-if="isLoading" mode="indeterminate" class="mt-2" />
                    </form>
                    <InlineMessage v-if="messageSuccess" severity="success" class="mt-3">
                        {{ $t(messageSuccess) }}
                    </InlineMessage>
                </div>
            </div>
        </main>
    </div>
</template>

<script >
import Checkbox from 'primevue/checkbox';
import InlineMessage from "primevue/inlinemessage";
import Rating from "primevue/rating";
import Skeleton from "primevue/skeleton";
import Textarea from 'primevue/textarea';
import { computed, ref } from "vue";

export default {
    components: { Rating, InlineMessage, Skeleton, Textarea, Checkbox },
    props: { token: { type: String, default: null } },
    setup() {
        const formObject = ref({
            qualityOfService: 0,
            timeResponse: 0,
            willingnessToRecommend: 0,
            generalEvaluation: 0,
            description: "",
            forfeitReasons: [],
        });
        const isLoading = ref(false);
        const isTokenValid = ref(false);
        const isForfeit = ref(false);
        const messageError = ref("");
        const messageSuccess = ref("");

        const isDisabled = computed(() => (isForfeit.value && formObject.value.forfeitReasons.length == 0)
            || (!isForfeit.value && (formObject.value.qualityOfService == 0 || formObject.value.timeResponse == 0
                || formObject.value.willingnessToRecommend == 0 || formObject.value.generalEvaluation == 0)));
        return {
            formObject, isLoading, isTokenValid, messageError, messageSuccess, isForfeit, isDisabled
        };
    },
    created() {
        this.tokenConfirmation();
    },
    mounted() {
        if (!this.token) {
            this.$router.push({ name: "login" }).catch(() => { });
        }
    },
    methods: {
        tokenConfirmation() {
            this.messageError = this.messageSuccess = null;
            this.$root
                .apiGet(this.$root.getApiSatisfactionSurvey(this.token))
                .then((response) => {
                    this.isForfeit = response.data.isForfeit;
                    this.isTokenValid = true;
                })
                .catch((error) => {
                    this.isTokenValid = false;
                    this.messageError = error.response.data.msg_code;
                });
        },
        submit() {
            this.messageError = this.messageSuccess = null;
            this.isLoading = true;
            this.$root.axiosSetAuth(null, false);
            this.$root
                .apiPostUnauthenticated(this.$root.getApiSatisfactionSurvey(this.token), this.formObject)
                .then(() => {
                    this.isLoading = false;
                    this.messageSuccess = "Obrigado pelo seu feedback";
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.messageError = error.response.data.msg_code;
                });
        }
    }
};

</script>

<style scoped>
.layout-topbar {
    display: list-item;
    top: 0;
}

h5 {
    margin-bottom: 10px;
}

main {
    margin-top: 120px;
    text-align: center;
}
</style>
<template>
  <div class="p-2">
    <Textarea v-model="message" :placeholder="$t('new_post_placeholder')" class="mb-2 col-12" rows="5" auto-resize />
    <div class="grid">
      <div class="col-12 sm:col-6 md:col-6 lg:col-6 pb-0">
        <FileUpload id="uploadFilesForPost" :disabled="isLoading" :max-file-size="20000000"
          :url="$root.api.fileManagerSave" :choose-label="$t('attach')" :invalid-file-size-message="$t('max_file_size_allowed', { fileSize: '{1}' })
      " class="attach-files" name="files[]" mode="basic" auto multiple choose-icon="pi pi-paperclip"
          upload-icon="pi pi-paperclip" @before-send="beforeUpload" @upload="afterUpload" @error="isLoading = false"
          @progress="setUploadProgress" />
      </div>
      <div class="col-12 sm:col-6 md:col-6 lg:col-6 flex-right-side h-full pb-0">
        <Button :disabled="isLoading" :label="$t('publish')"
          :class="{ 'button-publish': $root.user && $root.user.hasBalcaoMais }" @click="validateInputs" />
      </div>
    </div>
    <ProgressBar v-if="isLoading && uploadProgress !== 100" :value="uploadProgress" class="process-bar-separator" />
    <ProgressBar v-if="isLoading && (uploadProgress === 100 || uploadProgress == null)" class="text-center mb-2"
      mode="indeterminate" />
    <FilesUploaded v-if="filesUploaded.length || filesPersisted.length" :files-uploaded="filesUploaded"
      :files-persisted="filesPersisted" class="mb-4 mt-2" @remove-file="removeFileByUrl" />
  </div>
</template>

<script>
import ProgressBar from "primevue/progressbar";
import FilesUploaded from "../files/FilesUploaded.vue";

export default {
  components: { FilesUploaded, ProgressBar },
  props: { processId: { type: [String, Number], default: null }, },
  emits: ["post-created"],
  data() {
    return {
      message: "",
      isLoading: false,
      filesUploaded: [],
      filesPersisted: [],
      postId: null,
      uploadProgress: 0,
    };
  },
  methods: {
    setUploadProgress(event) {
      this.uploadProgress = event.progress;
    },
    beforeUpload(request) {
      this.isLoading = true;
      return request.xhr.setRequestHeader(
        "Authorization",
        this.$root.axiosGetAuth()
      );
    },
    afterUpload(request) {
      this.isLoading = false;
      let files = JSON.parse(request.xhr.response).data;
      files.forEach((file) => {
        file.context = "pending_posts";
        this.filesUploaded.push(file);
      });
    },
    removeFileByUrl(url) {
      this.filesUploaded = this.filesUploaded.filter(
        (file) => file.url !== url
      );
      this.filesPersisted = this.filesPersisted.filter(
        (file) => file.url !== url
      );
    },
    validateInputs() {
      if (!this.message || this.message.length == 0) {
        this.$root.toastError("message_is_required");
      } else {
        this.submit();
      }
    },
    submit() {
      this.isLoading = true;
      if (this.filesPersisted.length > 0) {
        this.createPostFiles();
      } else {
        this.createPost();
      }
    },
    createPost() {
      let parameters = {
        message: this.message,
        attachments: this.filesUploaded,
        processId: this.processId,
      };
      this.postId = null;
      this.$root
        .apiPost(this.$root.api.posts, parameters)
        .then((response) => {
          if (this.filesUploaded.length > 0) {
            this.postId = response.data.data;
            this.persistFile(response.data.data);
          } else {
            this.resetData();
            this.$root.toastSuccess(response.data.msg_code);
            this.$emit("post-created");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    persistFile() {
      this.filesUploaded.forEach((file) => {
        file.context = this.processId
          ? `process/${this.processId}/post/${this.postId}`
          : `post/${this.postId}`;
      });
      this.$root
        .apiPost(this.$root.api.fileManagerPersist, { files: this.filesUploaded })
        .then((response) => {
          this.filesUploaded = [];
          response.data.data.forEach((file) => {
            if (!file.url.startsWith("tmp/")) {
              this.filesPersisted.push(file);
            }
          });
          this.createPostFiles();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    createPostFiles() {
      let parameters = {
        attachments: this.filesPersisted,
        postId: this.postId,
        notify: true,
      };
      this.$root
        .apiPost(this.$root.api.postFiles, parameters)
        .then((response) => {
          this.resetData();
          this.$root.toastSuccess(response.data.msg_code);
          this.$emit("post-created");
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    resetData() {
      this.isLoading = false;
      this.message = "";
      this.filesUploaded = [];
      this.postId = null;
      this.filesPersisted = [];
    },
  },
};
</script>

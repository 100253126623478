<template>
  <div>
    <PostTemplate v-if="post" :post="post" />
    <ProgressBar v-if="isLoading" class="text-center" mode="indeterminate" />
  </div>
</template>

<script>
// This component is used when a user selects a post through notifications
import PostTemplate from "./PostTemplate.vue";

export default {
  components: { PostTemplate },
  props: { id: { type: [String, Number], default: null } },
  data() {
    return { post: null, isLoading: false };
  },
  watch: {
    id() {
      this.getPost();
    },
  },
  created() {
    this.getPost();
  },
  methods: {
    getPost() {
      this.post = null;
      this.isLoading = true;
      this.$root
        .apiGet(this.$root.getApiPosts(this.id), { params: { dataType: "list" } })
        .then((response) => {
          this.isLoading = false;
          this.post = response.data.data;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
  },
};
</script>

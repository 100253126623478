<template>
  <div class="grid">
    <div class="col-12 md:col-6">
      <div v-if="process.users" class="mb-2 flex">
        <span class="mr-2">{{ $t('process_managers') }}:</span>
        <ShowUserImage v-for="user in process.users" :key="user.id" v-tooltip.bottom="user.name" :url="user.imgUrl"
          img-class="rounded mr-2" :img-alt="`Foto de ${user.name}`" max-width="28" height="28" />
      </div>
      <div class="mb-3 post-card no-print">
        <CreatePost :processId="processId" :canShareWithClient="process.client && process.client.hasAuth"
          :isMegaProcess="process.isMegaProcess" @post-created="resetPosts" />
      </div>
      <PostTemplate v-for="post in posts" :key="post.id" :post="post" showRealUser fromProcess />
      <div v-if="hasMorePosts">
        <Button :disabled="isLoading" class="post-button-load-more" label="Carregar mais" @click="getPosts" />
        <ProgressBar v-if="isLoading" mode="indeterminate" />
      </div>
    </div>
    <div class="col-12 md:col-6">
      <ProcessInfo :process="process" />
    </div>
  </div>
</template>

<script>
import CreatePost from "../posts/CreatePost.vue";
import PostTemplate from "../posts/PostTemplate.vue";
import ProcessInfo from "./ProcessInfo.vue";
import ShowUserImage from "../files/ShowUserImage.vue";

const limit = 50;
export default {
  components: { PostTemplate, ProcessInfo, CreatePost, ShowUserImage },
  props: {
    processId: { type: [String, Number], default: null },
    process: { type: Object, default: null },
  },
  emits: ["changed"],
  data() {
    return {
      posts: [],
      isLoading: false,
      offset: 0,
      hasMorePosts: true,
    };
  },
  watch: {
    processId() {
      this.resetPosts();
    },
  },
  created() {
    this.getPosts();
  },
  methods: {
    resetPosts() {
      this.offset = 0;
      this.posts = [];
      this.getPosts();
      this.$emit("changed");
    },
    getPosts() {
      this.isLoading = true;
      let params = {
        offset: this.offset,
        processId: this.processId,
        limit: limit,
        dataType: "process"
      };
      this.$root
        .apiGet(this.$root.api.posts, { params })
        .then((response) => {
          this.isLoading = false;
          if (response.data.data.length !== 0) {
            this.posts.push(...response.data.data);
            this.offset += limit;
          } else {
            this.hasMorePosts = false;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
  },
};
</script>

<template>
    <Dialog v-model:visible="isVisible" :style="{ width: '50vw' }" :draggable="false" header="Dados em falta" modal
        :close-on-escape="false" :closable="false">
        <div class="p-fluid formgrid grid">
            <div v-if="missingData.includes('birthDate')" class="field col-12 md:col-6">
                <label>Data de Nascimento*</label>
                <Calendar v-model="formObject.birthDate" placeholder="31/12/2000" date-format="dd/mm/yy" show-icon />
            </div>
            <div v-if="missingData.includes('nif')" class="field col-12 md:col-6">
                <label>Nº Identificação Fiscal - País de residência*</label>
                <InputText v-model="formObject.nif" type="text" />
            </div>
            <div v-if="missingData.includes('address')" class="field col-12 md:col-6">
                <label>Morada do país de residência*</label>
                <InputText v-model="formObject.address" type="text" />
            </div>
            <div v-if="missingData.includes('postalCode')" class="field col-12 md:col-6">
                <label>Código-Postal*</label>
                <InputText v-model="formObject.postalCode" type="text" />
            </div>
            <div v-if="missingData.includes('district')" class="field col-12 md:col-6">
                <label>Distrito/Cantão*</label>
                <Dropdown v-model="formObject.district" :options="states" option-label="name" option-value="name"
                    placeholder="Selecione" scroll-height="400px" filter @change="getCities()">
                    <template #value="slotProps">
                        <div v-if="slotProps.value">{{ slotProps.value }}</div>
                        <span v-else>{{ slotProps.placeholder }}</span>
                    </template>
                </Dropdown>
            </div>
            <div v-if="missingData.includes('location')" class="field col-12 md:col-6">
                <label>Localidade*</label>
                <InputText v-model="formObject.location" type="text" />
            </div>
            <div v-if="missingData.includes('receivePromotion')" class="field col-12 md:col-6">
                <label>Subscrever mensagens promocionais</label>
                <InputSwitch v-model="formObject.receivePromotion"
                    v-tooltip.top="'Poderá alterar editar posteriormente no perfil (no canto superior direito)'"
                    class="flex" />
            </div>
        </div>
        <template #footer>
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" autofocus @click="submit" />
        </template>
    </Dialog>
</template>

<script>
import InputSwitch from 'primevue/inputswitch';

export default {
    components: { InputSwitch },
    props: { visible: Boolean },
    emits: ["dialog-closed"],
    data() {
        return {
            isVisible: false,
            formObject: {},
            missingData: [],
            states: [],
            cities: []
        };
    },
    watch: {
        visible(val) {
            this.isVisible = val;
        },
        isVisible(val) {
            if (!val) {
                this.closeDialog();
            }
        }
    },
    created() {
        this.isVisible = this.visible;
        this.getMissingData();
    },
    methods: {
        getMissingData() {
            this.$root
                .apiGet(this.$root.api.missingData)
                .then((response) => {
                    this.formObject = response.data.data;
                    this.missingData = Object.entries(this.formObject)
                        .filter((keyValueAsArray) => !keyValueAsArray[1])
                        .map((keyValueAsArray) => keyValueAsArray[0]);
                    this.getStates();
                    this.getCities();
                    if (this.missingData.includes("receivePromotion")) {
                        this.formObject.receivePromotion = true;
                    }
                })
                .catch((error) => {
                    this.$root.toastError(error.response.data.msg_code);
                });
        },
        getStates() {
            let params = { dataType: "short", country: this.$root.user.country };
            this.$root
                .apiGet(this.$root.api.states, { params })
                .then((response) => {
                    this.states = response.data.data;
                })
                .catch((error) => {
                    this.$root.toastError(error.response.data.msg_code);
                });
        },
        getCities() {
            if (!this.formObject.district) {
                return;
            }
            let params = {
                dataType: "short",
                country: this.$root.user.country,
                state: this.formObject.district
            };
            this.$root
                .apiGet(this.$root.api.cities, { params })
                .then((response) => {
                    this.cities = response.data.data;
                })
                .catch((error) => {
                    this.$root.toastError(error.response.data.msg_code);
                });
        },
        submit() {
            let formObject = Object.assign({}, this.formObject);
            formObject.birthDate = this.$root.setDateFromCalendar(
                formObject.birthDate
            );
            this.$root
                .apiPut(this.$root.api.missingData, formObject)
                .then(() => {
                    this.$emit("dialog-closed");
                })
                .catch((error) => {
                    this.$root.toastError(error.response.data.msg_code);
                });
        }
    }
};
</script>

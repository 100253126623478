<template>
    <div>
        <h1>{{ $t('processes') }}</h1>
        <TabView v-model:activeIndex="activeIndex" scrollable>
            <TabPanel v-for="state in processStates" :key="state.value">
                <template #header>
                    <span class="text-sm">{{ state.label }}</span>
                </template>
            </TabPanel>
        </TabView>
        <DataTable v-model:contextMenuSelection="selectedProcess" :value="filteredProcesses" :loading="isLoading"
            responsive-layout="scroll" selection-mode="single" context-menu
            class="p-datatable-striped p-datatable-gridlines p-datatable-sm pb-6 d-grid" @row-select="showProcess"
            @rowContextmenu="onRowContextMenu">
            <template #empty>
                <span>Não foram encontrados processos.</span>
            </template>
            <Column field="processTypeName" :header="$t('process_type')" sortable />
            <Column field="phaseName" :header="$t('process_phase')" sortable />
            <Column field="managerName" :header="$t('manager')" sortable />
            <Column field="createdAt" :header="$t('opening_date')" sortable />
        </DataTable>
        <ContextMenu ref="cm" :model="menuModel" />
    </div>
</template>

<script>
import ContextMenu from "primevue/contextmenu";
import DataTable from "primevue/datatable";
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import { ref } from "vue";
import useDropdownOptions from "../../composables/useDropdownOptions.js";

export default {
    components: { DataTable, ContextMenu, TabView, TabPanel },
    setup() {
        const activeIndex = ref(0);
        const { processStates, translateProcessStateToPt } = useDropdownOptions();
        return { processStates, translateProcessStateToPt, activeIndex };
    },
    data() {
        return {
            processes: [],
            isLoading: false,
            selectedProcess: null,
            menuModel: [
                {
                    label: "Abrir",
                    icon: "pi pi-fw pi-external-link",
                    command: () => this.openInNewTab(this.selectedProcess),
                }
            ]
        };
    },
    computed: {
        filteredProcesses() {
            if (!this.processStates) return [];
            let processState = this.processStates[this.activeIndex];
            if (!processState) {
                return [];
            }
            return this.processes[processState.value];
        },
    },
    created() {
        this.getProcesses();
    },
    methods: {
        getProcesses() {
            this.isLoading = true;
            this.$root.apiGet(this.$root.api.processes, { dataType: "client-list" })
                .then((response) => {
                    this.isLoading = false;
                    this.processes = response.data.data;
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$root.toastError(error.response.data.msg_code);
                });
        },
        showProcess(event) {
            if (!this.isSelect) {
                this.$router.push({
                    name: "process",
                    params: { id: event.data.id },
                });
            }
        },
        onRowContextMenu(event) {
            this.$refs.cm.show(event.originalEvent);
        },
        openInNewTab(process) {
            let routeData = this.$router.resolve({
                name: "process",
                params: { id: process.id },
            });
            window.open(routeData.href, "_blank");
        }
    }
};
</script>
<template>
    <div v-if="bussola" class="mb-4">
        <div :class="bussola.videoUrl ? 'cursor-pointer' : null">
            <ShowBussola :bussola="bussola" />
        </div>
    </div>
</template>

<script>
import ShowBussola from "./ShowBussola.vue";

export default {
    components: { ShowBussola },
    data() {
        return {
            bussola: null,
            showFile: false,
        };
    },
    created() {
        this.getBussola();
    },
    methods: {
        getBussola() {
            this.$root
                .apiGet(this.$root.api.externalApiBussolaLastPost)
                .then((response) => {
                    this.bussola = response.data[0];
                })
                .catch((error) => {
                    this.$root.toastError(error.response.code);
                });
        },
    },
};
</script>
<template>
    <div class="card post-card">
        <img :src="$root.images.financialCourse" alt="logo" class="w-full cursor-pointer" @click="openUrl" />
    </div>
</template>

<script>
export default {
    methods: {
        openUrl() {
            const url = "https://balcaomais.pt/curso-financeiro-para-clientes-balcao-mais";
            window.open(url, "_blank");
        }
    },
};
</script>

<style scoped>
.campaign-text {
    color: #575756;
}
</style>

<template>
  <div class="card news">
    <h3>
      {{ $t("news") }}
      <span class="text-sm">
        site:
        <a href="https://b-emigrante.com/" style="color: black" target="_blank">
          b-emigrante.com
        </a>
      </span>
    </h3>

    <ProgressBar v-if="isLoading && $root.news.length == 0" class="text-center" mode="indeterminate" />
    <div v-for="(n, index) in $root.news" v-else :key="index" class="news-divider">
      <h4>
        <a class="news-link" :href="n.link" target="_blank">{{
        formatTitle(n.title.rendered)
        }}</a>
      </h4>
      <div class="d-flow-root">
        <a v-if="
          n._embedded &&
          n._embedded['wp:featuredmedia'] &&
          n._embedded['wp:featuredmedia']['0'] &&
          n._embedded['wp:featuredmedia']['0'].source_url
        " :href="n.link" target="_blank" style="text-align: center; float: left;" class="mr-2">
          <img :src="n._embedded['wp:featuredmedia']['0'].source_url" class="news-image" />
        </a>
        <span v-html="$sanitize(n.excerpt.rendered)" />
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
    };
  },
  created() {
    if (!this.$root.news.length) {
      this.getBEmigranteToken();
    }
  },
  methods: {
    getBEmigranteToken() {
      this.isLoading = true;
      let type = { type: "WordpressBE" };
      this.$root
        .apiPost(this.$root.api.externalApiAuth, type)
        .then((response) => {
          this.$root.axiosWordpressBESetAuth(response.data.data);
          this.getNews();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    getNews() {
      this.$root
        .apiWordpressBEGet(this.$root.api.externalApiBEmigrantePosts)
        .then((response) => {
          this.$root.news = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    formatTitle(title) {
      return title.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
        return String.fromCharCode(charCode);
      })
    }
  },
};
</script>

<template>
  <div v-if="(filesUploaded && filesUploaded.length) || (filesPersisted && filesPersisted.length)"
    class="files-uploaded-box">
    <div v-for="(file, index) in filesUploaded" :key="file" :class="{ 'mt-4': index !== 0 }" class="file">
      <template v-if="file">
        <div class="image-box">
          <ShowUserImage v-if="$root.fileIsImage(file)" :url="file.url" />
        </div>
        <div class="text-box">
          <InputText v-model="file.realFilename" type="text" class="width-max-size" />
        </div>
        <Button icon="pi pi-times" @click="removeFileByUrl(file.url)" />
      </template>
    </div>
    <div v-for="(file, index) in filesPersisted" :key="file" :class="{ 'mt-4': index !== 0 }" class="file">
      <template v-if="file">
        <div class="image-box">
          <ShowUserImage v-if="$root.fileIsImage(file)" :url="file.url" />
        </div>
        <div class="text-box">
          <InputText v-model="file.name" type="text" class="width-max-size" />
        </div>
        <Button icon="pi pi-times" @click="removeFileByUrl(file.url)" />
      </template>
    </div>
  </div>
</template>

<script>
import ShowUserImage from "./ShowUserImage.vue";

export default {
  components: { ShowUserImage },
  props: {
    filesUploaded: { type: Array, default: null },
    filesPersisted: { type: Array, default: null },
  },
  emits: ["remove-file", "persist"],
  methods: {
    removeFileByUrl(url) {
      this.$emit("remove-file", url);
    },
    persist() {
      this.$emit("persist", this.filesUploaded);
    },
  },
};
</script>

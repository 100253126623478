<template>
  <div v-if="post && post.message.trim().length > 0" class="card news mb-4">
    <h2>Agenda</h2>
    <ProgressBar v-if="isLoading" class="text-center" mode="indeterminate" />
    <span class="text-sm" v-html="$sanitize(post.message)" />
    <div v-if="post.files && post.files.length" class="col">
      <PostFile v-for="(file, index) in post.files" :key="file.url" :file="file" class="mb-1" display-name
        @show-file="setDisplayFiles(post.files, index)" />
    </div>
    <DialogFiles v-if="filesToDisplay" :files="filesToDisplay" :display-files="displayFiles"
      :file-selected-index="fileSelectedIndex" @closed="displayFiles = false" />
  </div>
</template>

<script>
import PostFile from "../posts/PostFile.vue";
import DialogFiles from "../files/DialogFiles.vue";

export default {
  components: { PostFile, DialogFiles },
  data() {
    return {
      isLoading: false,
      post: null,
      displayFiles: null,
      fileSelectedIndex: 0,
      filesToDisplay: [],
    };
  },
  mounted() {
    this.getAgendas();
  },
  methods: {
    getAgendas() {
      this.isLoading = true;
      this.$root.apiGet(this.$root.api.postsAgendas, { params: { dataType: "list" } })
        .then((response) => {
          this.isLoading = false;
          this.post = response.data.data;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    setDisplayFiles(files, index) {
      this.filesToDisplay = files;
      this.fileSelectedIndex = index;
      this.displayFiles = true;
    }
  },
};

</script>

<template>
  <li v-if="contacts && contacts.length" >
    <div class="title">
      <h4>
        {{ $t("contacts") }}
      </h4>
    </div>
    <div class="pl-2">
      <div v-for="contact in contacts" :key="contact" class="mb-3">
        <div class="flex">
          <ShowUserImage :url="contact.imgUrl" img-class="rounded" width="50" height="50" />
          <div class="ml-2">
            <span v-if="contact.name">
              <b>{{ contact.name }}</b>
              <br />
            </span>
            <span v-if="contact.functionDescription">
              <small>{{ contact.functionDescription }}</small>
              <br />
            </span>
            <small v-if="contact.phone">
              <i class="pi pi-phone" />
              <span class="pl-2">
                <a :href="`tel:${contact.phone}`" class="phone-link">
                  {{ contact.phone }}
                </a>
              </span>
              <br />
            </small>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import ShowUserImage from "../files/ShowUserImage.vue";

export default {
  components: { ShowUserImage },
  props: { contacts: { type: Array, default: null } },
};
</script>

<template>
  <div>
    <Sidebar v-model:visible="isVisible" position="right" class="p-sidebar-md" style="min-width: 285px">
      <div class="d-flow-root">
        <div class="f-left">
          <h1>{{ $t("notifications") }}</h1>
        </div>
        <div v-if="notifications.length" class="f-right">
          <Chip class="cursor-pointer" :label="$t('delete_all_notifications')" @click="deleteAll" />
        </div>
      </div>
      <div v-if="isLoading" class="pb-3">
        <ProgressBar class="text-center" mode="indeterminate" />
      </div>
      <div v-for="notification in notifications" :key="notification.id"
        :class="{ 'notification-unread': notification.isRead == 0 }" class="grid mb-3 notification cursor-pointer">
        <div class="col-2 notification-image-separator" @click="showNotification(notification)">
          <ShowUserImage v-if="notification.imgUrl" :url="notification.type !== 'group'
            ? $root.getUrlFromFilePath(notification.imgUrl)
            : $root.user.hasBalcaoMais
              ? $root.images.balcaoMaisIcon
              : $root.user.realm == 'BE'
                ? $root.images.beLogoBall
                : $root.images.pafSymbol" img-class="rounded" width="50" height="50" />
        </div>

        <div class="col-8" @click="showNotification(notification)">
          <b v-html="$sanitize(notification.title)"></b>
          <div class="my-1" v-html="$sanitize(notification.description)"></div>
          <small>{{ dateDifference(notification.createdAt) }}</small>
        </div>
        <div class="col-2">
          <Button class="p-button-rounded p-button-outlined remove-files-button" icon="pi pi-times"
            @click="deleteNotification(notification)" />
        </div>
      </div>
    </Sidebar>
  </div>
</template>

<script>
import Chip from "primevue/chip";
import ShowUserImage from "../files/ShowUserImage.vue";

export default {
  components: { Chip, ShowUserImage },
  props: { visible: Boolean },
  emits: ["sidebar-closed", "number-of-notifications-changed"],
  data() {
    return {
      isVisible: false,
      notifications: [],
      isLoading: false,
      lastId: 0,
    };
  },
  watch: {
    visible(val) {
      this.isVisible = val;
    },
    isVisible(val) {
      if (!val) {
        this.$emit("sidebar-closed");
      } else {
        this.resetTitle();
      }
    },
  },
  created() {
    this.getNotifications(false);
  },
  methods: {
    getNotifications(showNewNotifications, showNewNotificationsNative) {
      this.isLoading = true;
      let countNotifications = this.notifications.length;
      this.$root
        .apiGet(this.$root.api.notifications)
        .then((response) => {
          this.isLoading = false;
          this.notifications = response.data.data;
          let newNotificationsCount = response.data.data.filter(
            (n) => n.isRead == 0
          ).length;
          this.$emit("number-of-notifications-changed", newNotificationsCount);
          if (!showNewNotifications) {
            this.lastId = this.notifications.length
              ? this.notifications[0].id
              : 0;
          } else if (countNotifications < this.notifications.length) {
            document.title = "Nova notificação";
            setTimeout(function () {
              document.title = `Balcão do Emigrante (${newNotificationsCount})`;
            }, 2000);
            if (showNewNotificationsNative) {
              this.showNativeNotifications();
            }
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    async showNativeNotifications() {
      let ids = [];
      for (let notification of this.notifications) {
        if (ids.includes(notification.id)) {
          continue;
        }
        ids.push(notification.id);
        if (notification.id > this.lastId) {
          await new Promise(r => setTimeout(r, 10000));
          this.$notification.show(
            notification.title,
            { body: notification.description },
            {}
          )
        } else {
          this.lastId = this.notifications[0].id;
          break;
        }
      }
    },
    dateDifference(date) {
      return this.$root.differenceFromNow(date);
    },
    showNotification(notification) {
      if (notification.isRead == 0) {
        this.updateNotification(notification.id);
      }
      if (notification.processId > 0) {
        this.isVisible = false;
        this.$router.push({
          name: "process",
          params: { id: notification.processId },
        });
      } else if (notification.postId > 0) {
        this.isVisible = false;
        this.$router.push({
          name: "post",
          params: { id: notification.postId },
        });
      }
    },
    updateNotification(id) {
      this.resetTitle();
      this.notifications.find((notification) => notification.id == id).isRead = 1;
      let newNotificationsCount = this.notifications.filter((n) => n.isRead == 0).length;
      this.$emit("number-of-notifications-changed", newNotificationsCount);
      this.$root
        .apiPut(this.$root.getApiNotifications(id))
        .catch(error => this.$root.toastError(error.response.data.msg_code));
    },
    deleteNotification(notification) {
      this.resetTitle();
      this.notifications.splice(this.notifications.findIndex((n) => n.id == notification.id), 1);
      let newNotificationsCount = this.notifications.filter((n) => n.isRead == 0).length;
      this.$emit("number-of-notifications-changed", newNotificationsCount);
      this.$root
        .apiDelete(this.$root.getApiNotifications(notification.id))
        .catch(error => this.$root.toastError(error.response.data.msg_code));
    },
    deleteAll() {
      this.resetTitle();
      this.$root
        .apiDelete(this.$root.api.notifications)
        .then((response) => {
          this.$root.toastSuccess(response.data.msg_code);
          this.notifications = [];
          this.$emit("sidebar-closed");
          this.$emit("number-of-notifications-changed", 0);
        })
        .catch((error) => {
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    resetTitle() {
      document.title = "Área de Cliente - Balcão do Emigrante";
    },
  },
};
</script>

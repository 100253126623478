<template>
    <div>
        <DataView :value="webinars" layout="grid">
            <template #grid="slotProps">
                <div class="col-12 sm:col-6 lg:col-4 xl:col-3 p-2"
                    :class="slotProps.data.videoUrl ? 'cursor-pointer' : null" @click="setShowFile(slotProps.data)">
                    <div class="p-4 border-1 surface-border border-round h-full">
                        <div class="mb-3 align-items-center">
                            <ShowUserImage v-if="slotProps.data.imageUrl" :url="slotProps.data.imageUrl"
                                img-class="w-full" />
                        </div>
                        <div class="mb-2 font-bold">{{ $root.formatDateTime(slotProps.data.startDate) }}</div>
                        <div class="mb-4 font-semibold">{{ slotProps.data.title }}</div>
                        <div v-if="slotProps.data.authors" class="mb-2">Autor(es): {{ slotProps.data.authors }}</div>
                        <div>{{ slotProps.data.description }}</div>
                    </div>
                </div>
            </template>
        </DataView>
        <DialogFiles :display-files="showFile" :files="[{ url: fileUrl, name: fileName }]" autoplay
            @closed="showFile = false" />
    </div>
</template>

<script>
import DataView from 'primevue/dataview';
import DialogFiles from "../files/DialogFiles.vue";
import ShowUserImage from "../files/ShowUserImage.vue";

export default {
    components: { DataView, DialogFiles, ShowUserImage },
    data() {
        return {
            webinars: [],
            isLoading: false,
            showFile: false,
            fileUrl: null,
            fileName: null,
        };
    },
    created() {
        this.getWebinars();
    },
    methods: {
        getWebinars() {
            this.isLoading = true;
            let params = { dataType: "client", isActive: true };
            this.$root
                .apiGet(this.$root.api.webinars, { params })
                .then((response) => {
                    this.isLoading = false;
                    this.webinars = response.data.data;
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$root.toastError(error.response.data.msg_code);
                });
        },
        setShowFile(webinar) {
            if (!webinar.videoUrl) {
                return;
            }
            if (webinar.videoUrl.startsWith("files/") || webinar.videoUrl.startsWith("https://quadrante.")) {
                this.fileUrl = webinar.videoUrl;
                this.fileName = webinar.title;
                this.showFile = true;
            } else {
                window.open(webinar.videoUrl, '_blank');
            }
        }
    },
};
</script>
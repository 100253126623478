<template>
  <div class="login-page">
    <Dropdown
      id="languageDropdown"
      v-model="languageSeleced"
      :options="languages"
    />
    <div class="login-form">
      <img :src="$root.images.beLogo" />
      <h1 class="text-2xl bold upper blue py-1">{{ title }}</h1>
      <form @submit.prevent>
        <slot>
          <!--for components that extends this one-->
        </slot>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: { title: { type: String, default: null } },
  data() {
    return {
      languageSeleced: this.$i18n.locale,
      languages: ["PT", "EN"],
    };
  },
  watch: {
    languageSeleced() {
      this.$i18n.locale = this.languageSeleced;
      this.$root.setDateLocale(this.languageSeleced);
      this.$store.commit("setLocale", this.languageSeleced);
    },
  },
  mounted() {
    let body = document.getElementsByTagName("BODY")[0];
    body.classList.add("login__background");
  },
  unmounted() {
    let body = document.getElementsByTagName("BODY")[0];
    body.classList.remove("login__background");
  },
};
</script>

<style scoped>
#languageDropdown {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>

<style scoped>
@import "../../../public/assets/css/login.css";
</style>

<template>
    <div>
        <h1>{{ $t('services') }}</h1>
        <DataTable :value="services" :loading="isLoading" responsive-layout="scroll"
            class="p-datatable-striped p-datatable-gridlines p-datatable-sm pb-6 d-grid">
            <template #empty>
                <span>Não foram encontrados serviços.</span>
            </template>
            <Column field="serviceName" :header="$t('service_name')" sortable />
            <Column field="createdAt" :header="$t('registration_date')" sortable />
        </DataTable>
    </div>
</template>

<script>
import DataTable from "primevue/datatable";

export default {
    components: { DataTable },
    data() {
        return {
            services: [],
            isLoading: false,
        };
    },
    created() {
        this.getClientServices();
    },
    methods: {
        getClientServices() {
            this.isLoading = true;
            this.$root.apiGet(this.$root.api.clientSale, { dataType: "client-list" })
                .then((response) => {
                    this.isLoading = false;
                    this.services = response.data.data;
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.$root.toastError(error.response.data.msg_code);
                });
        }
    }
};
</script>
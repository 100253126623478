<template>
  <div>
    <Sidebar v-model:visible="isVisible" :show-close-icon="!isLoading" :dismissable="false" class="p-sidebar-md mb-6"
      position="right">
      <h1>{{ $t("edit_profile") }}</h1>
      <ProgressBar v-if="isLoading" mode="indeterminate" />
      <div v-else class="p-fluid formgrid grid mb-6">
        <div class="field col-12 md:col-6">
          <label>
            <b>{{ $t("profile_image") }}</b>
          </label>
          <div>
            <!--Default picture-->
            <img v-if="!formObject.imgUrl && !fileUploaded" :src="$root.images.defaultPicture" class="mr-3"
              style="max-height: 100px" />
            <!--Client picture-->
            <ShowUserImage v-if="formObject.imgUrl && !fileUploaded" :url="formObject.imgUrl" img-class="mr-3"
              style="max-height: 100px" />
            <!--Uploaded picture-->
            <div v-if="fileUploaded" class="mr-3">
              <Galleria v-model:visible="showImageFullScreen" :value="[fileUploaded]" :show-thumbnails="false"
                full-screen>
                <!--FullScreen picture-->
                <template #item="slotProps">
                  <ShowUserImage :url="slotProps.item.url" img-class="w-full" :img-alt="slotProps.item.realFilename" />
                </template>
              </Galleria>
              <ShowUserImage :url="fileUploaded.url" img-class="cursor-pointer" :img-alt="fileUploaded.realFilename"
                style="max-height: 100px" @click="showImageFullScreen = true" />
              <br />
            </div>
            <div class="flex-column">
              <div class="col pb-3">
                <FileUpload :url="$root.api.fileManagerSave" :max-file-size="1000000" :choose-label="$t('add_file')"
                  :invalid-file-size-message="$t('max_file_size_allowed', { fileSize: '{1}' })
                    " class="p-fileupload-buttonbar p-fileupload-content" mode="basic" name="files[]" accept="image/*"
                  auto @before-send="beforeUpload" @upload="afterUpload" @error="isLoading = false" />
              </div>
              <div v-if="fileUploaded" class="col">
                <Button :label="$t('cancel')" class="p-button p-button-outlined" type="button" icon="pi pi-times"
                  @click="cancelPhotoUpload" />
              </div>
            </div>
          </div>
          <small v-if="fileUploaded">{{ fileUploaded.realFilename }}</small>
        </div>
        <div class="field col-12 md:col-6">
          <label><b>Subscrever mensagens promocionais</b></label>
          <InputSwitch v-model="formObject.receivePromotion" class="flex" />
        </div>

        <div class="field col-12 md:col-6">
          <label>
            <b>{{ $t("first_names") }}</b>
          </label>
          <InputText v-model="formObject.firstNames" type="text" />
        </div>
        <div class="field col-12 md:col-6">
          <label>
            <b>{{ $t("last_names") }}</b>
          </label>
          <InputText v-model="formObject.lastNames" type="text" />
        </div>
        <div class="field col-12 md:col-6">
          <label>
            <b>{{ $t("profession") }}</b>
          </label>
          <Dropdown v-model="formObject.profession" :options="professions" option-label="name" option-value="name"
            placeholder="Escreva ou selecione" scroll-height="400px" filter show-clear />
        </div>
        <div class="field col-12 md:col-6">
          <label>
            <b>{{ $t("marital_status") }}</b>
          </label>
          <Dropdown v-model="formObject.maritalStatus" :options="$root.options.maritalStatus"
            :placeholder="$t('dropdown_select')" option-label="label" option-value="value" />
        </div>
        <div class="field col-12 md:col-6">
          <label>
            <b>{{ $t("birth_date") }}</b>
          </label>
          <Calendar v-model="formObject.birthDate" :show-icon="true" placeholder="31/12/1950" date-format="dd/mm/yy" />
        </div>
        <div class="field col-12 md:col-6">
          <label>
            <b>{{ $t("naturalness") }}</b>
          </label>
          <InputText v-model="formObject.naturalness" type="text" />
        </div>
        <div class="field col-12 md:col-6">
          <label>
            <b>{{ $t("address") }}</b>
          </label>
          <InputText v-model="formObject.address" type="text" />
        </div>
        <div class="field col-12">
          <label class="col-12 p-0">
            <b>{{ $t("phones") }}</b>
          </label>
          <label class="col-3 md:col-3 sm:col-3">{{ $t("phone_description") }}:</label>
          <label class="col-3 md:col-3 sm:col-3">{{ $t("phone_code") }}:</label>
          <label class="col-6 md:col-6 sm:col-6">{{ $t("phone_number") }}:</label>
          <div v-for="(clientPhone, key) in formObject.clientPhones" :key="key" class="grid">
            <div class="field col-12 md:col-3 sm:col-12">
              <Dropdown v-model="clientPhone.description" :options="$root.options.phones"
                :placeholder="$t('dropdown_select')" />
            </div>
            <div class="field col-12 md:col-3 sm:col-12">
              <Dropdown v-model="clientPhone.code" :options="countries" :placeholder="$t('dropdown_select')"
                option-label="name" option-value="phoneCode" filter show-clear>
                <template #value="slotProps">
                  <div v-if="slotProps.value" key="has-country-selected">+{{ clientPhone.code }}</div>
                  <div v-else key="select-country">{{ slotProps.placeholder }}</div>
                </template>
                <template #option="slotProps">{{ slotProps.option.name }}</template>
              </Dropdown>
            </div>
            <div class="field col-12 md:col-5 sm:col-12">
              <InputText v-model="clientPhone.phone" placeholder="912123123" type="text" />
            </div>
            <div class="field col-12 md:col-1 sm:col-12">
              <Button class="p-button-danger p-button-sm" icon="pi pi-times" @click="removePhoneNumber(key)" />
            </div>
          </div>
          <div class="text-center col pt-0">
            <Button class="p-button-sm" icon="pi pi-plus" @click="addPhoneNumber" />
          </div>
        </div>

        <div class="field col-12">
          <label class="col-12 p-0">
            <b>{{ $t("emails") }}</b>
          </label>
          <label class="col-4 md:col-4 sm:col-4">{{ $t("email_description") }}:</label>
          <label class="col-8 md:col-8 sm:col-8">{{ $t("email_email") }}:</label>
          <div v-for="(clientEmail, key) in formObject.clientEmails" :key="key" class="grid">
            <div class="field col-12 md:col-4 sm:col-12">
              <Dropdown v-model="clientEmail.description" :options="$root.options.emails"
                :placeholder="$t('dropdown_select')" />
            </div>
            <div class="field col-12 md:col-7 sm:col-12">
              <Textarea v-model="clientEmail.email" type="text" rows="1" auto-resize />
            </div>
            <div class="field col-1 md:col-1 sm:col-12">
              <Button class="p-button-danger p-button-sm" icon="pi pi-times" @click="removeEmail(key)" />
            </div>
          </div>
          <div class="text-center col pt-0">
            <Button class="p-button-sm" icon="pi pi-plus" @click="addEmail" />
          </div>
        </div>

        <div class="field col-12 mb:col-6">
          <div class="field col">
            <label>
              <Button :label="$t('change_password')" class="p-button-sm" @click="editPassword = !editPassword" />
            </label>
            <div v-if="editPassword" class="grid formgrid">
              <div class="field col-12 md:col-6">
                <label>
                  <b>{{ $t("password") }}</b>
                </label>
                <InputText v-model="password" type="password" autocomplete="on" />
              </div>
              <div class="field col-12 md:col-6">
                <label>
                  <b>{{ $t("password_confirmation") }}</b>
                </label>
                <InputText v-model="passwordConfirmation" type="password" autocomplete="on" />
              </div>
              <small>{{ $t("password_rules") }}</small>
            </div>
          </div>
        </div>
      </div>
      <FormFooter :show-keep-window-open="false" :is-loading="isLoading" :submit-text="$t('save')"
        @submit="validateInputs" @cancel="isVisible = false" />
    </Sidebar>
  </div>
</template>

<script>
import FormFooter from "../utils/FormFooter.vue";
import InputSwitch from 'primevue/inputswitch';
import ShowUserImage from "../files/ShowUserImage.vue";

export default {
  components: { FormFooter, InputSwitch, ShowUserImage },
  props: { visible: Boolean },
  emits: ["sidebar-closed"],
  data() {
    return {
      isVisible: false,
      formObject: {},
      password: "",
      passwordConfirmation: "",
      isLoading: false,
      editPassword: false,
      maritalStatusOptions: [
        { text: "Solteiro(a)", value: "SINGLE" },
        { text: "Divorciado(a)", value: "DIVORCED" },
        { text: "Viúvo(a)", value: "WIDOWED" },
        {
          text: "Casado(a) - Comunhão de adquiridos",
          value: "MARRIED_ACQUIRED",
        },
        {
          text: "Casado(a) - Comunhão geral de bens",
          value: "MARRIED_GENERAL",
        },
        {
          text: "Casado(a) - Separação de bens",
          value: "MARRIED_SEPARATION",
        },
        {
          text: "Casado(a) - Outros regimes que os nubentes convencionem",
          value: "MARRIED_OTHER",
        },
      ],
      countries: [],
      professions: [],
      fileUploaded: null,
      filePersisted: null,
      showImageFullScreen: false,
    };
  },
  watch: {
    visible(val) {
      this.isVisible = val;
    },
    isVisible(val) {
      let clone = this.$options.data();
      delete clone.isVisible;
      Object.assign(this.$data, clone);
      if (!val) {
        this.$emit("sidebar-closed");
      } else {
        this.getClient();
        this.getCountries();
        this.getProfessions();
      }
    },
  },
  methods: {
    beforeUpload(request) {
      this.isLoading = true;
      return request.xhr.setRequestHeader(
        "Authorization",
        this.$root.axiosGetAuth()
      );
    },
    afterUpload(request) {
      this.isLoading = false;
      this.filePersisted = null;
      this.fileUploaded = JSON.parse(request.xhr.response).data[0];
      this.fileUploaded.context = "profile";
    },
    cancelPhotoUpload() {
      this.fileUploaded = this.filePersisted;
    },
    validateInputs() {
      let isInvalidInput = false;
      if (this.editPassword && this.password !== "") {
        if (this.password !== this.passwordConfirmation) {
          this.$root.toastError("passwords_dont_match");
          isInvalidInput = true;
        }
        let passwordValid = this.password.match(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])^.{8,}$/
        );
        if (passwordValid == null) {
          this.$root.toastError("password_rules");
          isInvalidInput = true;
        }
      }
      this.formObject.clientPhones.forEach((phone) => {
        if (
          phone.description.length == 0 ||
          phone.code.length == 0 ||
          phone.phone.length == 0
        ) {
          this.$root.toastError("all_phone_fields_are_required");
          isInvalidInput = true;
        }
      });
      this.formObject.clientEmails.forEach((email) => {
        if (
          email.description.length == 0 ||
          email.email.length == 0 ||
          !this.$root.emailRegex.test(email.email)
        ) {
          this.$root.toastError(
            this.$t("specific_email_is_invalid", {
              email: email.email,
            })
          );
          isInvalidInput = true;
        }
      });
      if (!isInvalidInput) {
        this.isLoading = true;
        if (this.fileUploaded && !this.filePersisted) {
          this.persistFile();
        } else {
          this.updateProfile();
        }
      }
    },
    persistFile() {
      if (this.formObject.imgUrl) {
        this.fileUploaded.replaceFile = this.formObject.imgUrl;
      }
      let files = {
        files: [this.fileUploaded],
      };
      this.$root
        .apiPost(this.$root.api.fileManagerPersist, files)
        .then((response) => {
          this.filePersisted = this.fileUploaded;
          this.fileUploaded = null;
          this.formObject.imgUrl = response.data.data[0].url;
          this.updateProfile();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    updateProfile() {
      this.isLoading = true;
      let formObject = Object.assign({}, this.formObject);
      if (this.editPassword) {
        formObject.password = this.password;
        formObject.passwordConfirmation = this.passwordConfirmation;
      }
      formObject.birthDate = this.$root.setDateFromCalendar(
        formObject.birthDate
      );
      this.$root
        .apiPut(this.$root.api.profile, formObject)
        .then((response) => {
          this.isLoading = false;
          this.$root.toastSuccess(response.data.msg_code);
          setTimeout(() => {
            this.isVisible = false; //close the sidebar
          }, 1000);
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    getClient() {
      this.isLoading = true;
      this.$root.apiGet(this.$root.api.profile, { params: { dataType: "profile" } })
        .then((response) => {
          this.isLoading = false;
          this.formObject = response.data.data;
          if (!this.formObject.clientPhones.length) {
            this.addPhoneNumber();
          }
          if (!this.formObject.clientEmails.length) {
            this.addEmail();
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    addPhoneNumber() {
      if (this.formObject)
        this.formObject.clientPhones.push({ description: "", code: null, phone: "" });
    },
    addEmail() {
      this.formObject.clientEmails.push({ description: "", email: "" });
    },
    removePhoneNumber(key) {
      delete this.formObject.clientPhones[key];
      this.formObject.clientPhones.splice(key, 1);
    },
    removeEmail(key) {
      delete this.formObject.clientEmails[key];
      this.formObject.clientEmails.splice(key, 1);
    },
    getCountries() {
      this.$root
        .apiGet(this.$root.api.countries, { params: { dataType: "short" } })
        .then((response) => {
          this.countries = response.data.data;
        })
        .catch((error) => {
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    getProfessions() {
      this.$root
        .apiGet(this.$root.api.professions)
        .then((response) => {
          this.professions = response.data.data;
        })
        .catch((error) => {
          this.$root.toastError(error.response.data.msg_code);
        });
    },
  },
};
</script>

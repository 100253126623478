<template>
  <LoginBaseTemplate :title="$t('reset_password')">
    <div v-if="isTokenValid">
      <div v-if="showForm">
        <p>
          <input
            v-model="password"
            :placeholder="$t('password')"
            type="password"
            autocomplete="on"
          />
          <input
            v-model="passwordConfirmation"
            :placeholder="$t('password_confirmation')"
            type="password"
            autocomplete="on"
          />
          <small>{{ $t("password_rules") }}</small>
        </p>
        <button type="button" @click="validatePassword()">
          {{ $t("recover_account") }}
        </button>
        <ProgressBar
          v-if="isLoading"
          class="text-center"
          mode="indeterminate"
        />
      </div>
      <p v-if="messageError">
        <InlineMessage severity="error">{{ $t(messageError) }}</InlineMessage>
      </p>
      <div v-if="messageSuccess">
        <p>
          <InlineMessage severity="success">
            {{ $t(messageSuccess) }}
          </InlineMessage>
        </p>
        <button type="button" @click="goToLogin">
          {{ $t("login") }}
        </button>
      </div>
      <p v-else class="message text-sm">
        <a @click="goToLogin">{{ $t("login") }}</a>
      </p>
    </div>

    <div v-else-if="messageError">
      <InlineMessage severity="error">{{ $t(messageError) }}</InlineMessage>
      <button type="button" @click="goToLogin">
        {{ $t("login") }}
      </button>
    </div>
  </LoginBaseTemplate>
</template>

<script>
import LoginBaseTemplate from "./LoginBaseTemplate.vue";
import InlineMessage from "primevue/inlinemessage";

export default {
  components: { LoginBaseTemplate, InlineMessage },
  extends: LoginBaseTemplate,
  data() {
    return {
      password: "",
      passwordConfirmation: "",
      token: null,
      isTokenValid: false,
      isLoading: false,
      showForm: true,
      messageError: null,
      messageSuccess: null,
    };
  },
  created() {
    this.tokenConfirmation();
  },
  methods: {
    getToken() {
      return new URLSearchParams(window.location.search).get("token");
    },
    tokenConfirmation() {
      this.messageError = this.messageSuccess = null;
      this.$root
        .apiPost(this.$root.api.tokenConfirmation, { token: this.getToken() })
        .then(() => {
          this.isTokenValid = true;
        })
        .catch((error) => {
          this.messageError = error.response.data.msg_code;
        });
    },
    validatePassword() {
      this.messageError = null;
      if (this.password !== this.passwordConfirmation) {
        this.messageError = "passwords_dont_match";
      }
      if (
        this.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])^.{8,}$/) == null
      ) {
        this.messageError = "password_rules";
      }
      if (!this.messageError) {
        this.setPassword();
      }
    },
    setPassword() {
      let credentials = {
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
        token: this.getToken(),
      };
      this.isLoading = true;
      this.messageError = this.messageSuccess = null;
      this.$root
        .apiPutUnauthenticated(this.$root.api.resetPassword, credentials)
        .then((response) => {
          this.isLoading = this.showForm = false;
          this.messageSuccess = response.data.msg_code;
        })
        .catch((error) => {
          this.isLoading = false;
          this.messageError = error.response.data.msg_code;
        });
    },
    goToLogin() {
      this.$router.push({ name: "login" }).catch(() => {});
    },
  },
};
</script>
